import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  InternationalDocument,
  InternationalDocumentListEntities,
  Country
} from "@/domain/entities/InternationalDocument";

export class InternationalDocumentMapper {
  public convertListDataFromApi(res: AxiosResponse): InternationalDocumentListEntities {
    const { data } = res;

    return new InternationalDocumentListEntities({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data?.length
        ? data.data.map(
          (key: any) =>
            new InternationalDocument({
                idcId: key.idc_id,
                idcCountryId: key.idc_country_id,
                idcIsOtherCommodity: key.idc_is_other_commodity,
                idcIsReceiverEmail: key.idc_is_receiver_email,
                idcIsBeforeAndAfterPackingImage: key.idc_is_before_and_after_packing_image,
                idcIsKtpImage: key.idc_is_ktp_image,
                idcIsNpwpImage: key.idc_is_npwp_image,
                idcStatus: key.idc_status,
                country: new Country({
                    id: key.country.id,
                    code : key.country.code,
                    name : key.country.name,
                    status : key.country.status,
                    currency : key.country.currency,
                    createdBy : key.country.created_by,
                    updatedBy : key.country.updated_by,
                    createdAt : key.country.created_at,
                    updatedAt : key.country.updated_at,
                })
            })
        )
        : []
    });
  }

}