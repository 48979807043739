import { InternationalDocumentApiRepository } from "@/app/infrastructures/repositories/api/InternationalDocumentApiRepository";
import {
    InternationalDocumentListEntities,
    RequestInternationalDocumentList
} from "@/domain/entities/InternationalDocument";
import { injectable } from "tsyringe";

@injectable()
export class InternationalDocumentPresenter {
  private repository: InternationalDocumentApiRepository;
  constructor(repo: InternationalDocumentApiRepository) {
    this.repository = repo;
  }

  public getInternationalDocumentList(
    params: RequestInternationalDocumentList
  ): Promise<InternationalDocumentListEntities> {
    return this.repository.getInternationalDocumentList(params);
  }
}
