import { countries } from "@/app/infrastructures/misc/BulkData";
import sikatCampaignRules from "@/app/infrastructures/misc/common-library/SikatCampaignRules";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { flags } from "@/feature-flags";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { UploadHydraFileRequest, DeleteHydraFileRequest } from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { codDfodLabel } from "@/app/ui/modules/receipt-v2/module";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";

export const SHIPMENT_ID_FAVOURITE = ["AS", "AP"];
export const paginationStyle = "v3";
export const maxKg = 400;
export const MAX_WEIGHT_SHIPMENT = 10;
export const codePhoneCountry = (phone: string) => {
  let code = "+62";
  let flag = "indonesia";
  let newPhone = phone;

  for (const item of countries) {
    if (phone.startsWith(item.code.substring(1))) {
      code = item.code;
      flag = item.flag;
      newPhone = phone.substring(item.code.length - 1, phone.length);
    }
  }

  return {
    code: code,
    flag: flag,
    number: newPhone.startsWith("0") ? newPhone.substring(1) : newPhone
  };
};

export const nationalPrefixExist = (code: string) => {
  const codeExist = countries.find((item: any) => item.code === code);
  if (codeExist) {
    return codeExist.nationalPrefix;
  }

  return "";
};

export const totalGrossWeightSikat = (
  isCampaign: any,
  x: number,
  y: number,
  totalGrossWeight: any,
  totalVolumeWeight: any
) => {
  return isCampaign
    ? sikatCampaignRules(x, y, totalGrossWeight, totalVolumeWeight)
    : "-";
}

export const totalGrossWeightNonSikat = (isCampaign: any, x: number, y: number) => {
  let total = "-";
  if (isCampaign) {
    total = `${x > y ? x : y}`;
  }
  return total;
}

export const clientMapperSikat = (prefix: string) => {
  let clientId = -1;
  if (prefix.match(/^AP|^AS|^AG|^AD|^ACA|^ACB|^AI|^AO|^C1|^C2/gi)) {
    clientId = 2311;
  } else if (prefix.match(/^DO/gi)) {
    clientId = 2490;
  } else if (prefix.match(/^ACR/gi)) {
    clientId = 2489;
  } else if (prefix.match(/^CCR/gi)) {
    clientId = 2491;
  } else if (prefix.match(/^B1|^B2/gi)) {
    clientId = 2273;
  } else if (prefix.match(/^T1|^TKLP/gi)) {
    clientId = 2095;
  }

  return clientId;
};

export const promoPrefix = (shipmentId: string) => {
  const prefixList = FlagsMedusa.all_prefix_shipments.getValue().split(",");
  const findPrefix = prefixList.find((item: string) =>
    shipmentId.toUpperCase().startsWith(item)
  );
  return findPrefix ?? "";
}

export const validateCHWeight = (shipmentId: string) => {
  return shipmentId.match(/^T1|^TKLP/gi)
}


export const prefixDisableDimension = (shipmentId: string) => {
  const prefixList = FlagsMedusa.prefix_shipment_dimension_disable.getValue().split(",");
  const findPrefix = prefixList.find((item: string) =>
    shipmentId.toUpperCase().startsWith(item)
  );
  return !!findPrefix;
}

export const volumeWeightForProduct = (productType: string) => {
  const volume = 6000;
  const data: any = JSON.parse(FlagsMedusa.config_volumetric_by_product.getValue());
  return data[productType] ?? volume;
}

export const prefixDisableDimensionC1 = (posId: string) => {
  const userList = FlagsMedusa.user_whitelist_for_edit_shipment_c1.getValue().split(",");
  const findUser = userList.find((item: string) => item === posId
  );
  return !!findUser && AccountController.accountData.isPosAccount;
}

export const uploadAdjustmentFile = async (params: UploadMultiple) => {
  const fileLink = await BookingController.uploadHydraFile(new UploadHydraFileRequest({
    attachFile: params.file,
    folderPath: "stt/"
  }))

  return fileLink.data || "";
}

export const deleteAdjustmentFile = async (file: any) => {
  const del = await BookingController.deleteHydraFile(new DeleteHydraFileRequest({
    attachFiles: [file]
  }))

  return del;
}

export const detailClientManagement = async (id: number) => {
  await ClientManagementController.getDetail(id)
  const deliveryType = codDfodLabel(
    ClientManagementController.detailClientManagement.clientIsCod,
    ClientManagementController.detailClientManagement.clientIsDfod
  );
  return deliveryType;
}

export const gapValidationCHWeightTokped =  (totalChargeableWeightKeeped: number, totalChargeableWeight: number) => {
  const limit = Number(FlagsPermissionBooking.tokopedia_gap_weight_validation_prefix.getValue())
  const gap = totalChargeableWeight - totalChargeableWeightKeeped;

  return Math.abs(gap) > limit;
}