/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "./Pagination";

export class STTPiecesDetail {
  sttNumber: string;
  sttNumberPieces: string;
  destinationCity: string;
  productType: string;
  recentStatus: string;
  pickupPoint: string;
  woodPacking: string;
  commodity: string;
  cod: number;
  piecesNumber: string;
  grossWeight: number;
  volumeWeight: number;
  sttElexysNumber?: string;
  constructor(
    sttNumber: string,
    sttNumberPieces: string,
    destinationCity: string,
    productType: string,
    recentStatus: string,
    pickupPoint: string,
    woodPacking: string,
    commodity: string,
    cod: number,
    piecesNumber: string,
    grossWeight: number,
    volumeWeight: number,
    sttElexysNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.sttNumberPieces = sttNumberPieces;
    this.destinationCity = destinationCity;
    this.recentStatus = recentStatus;
    this.productType = productType;
    this.pickupPoint = pickupPoint;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.cod = cod;
    this.piecesNumber = piecesNumber;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class STTToBePickup {
  sttNumber: string;
  grossWeight: number;
  volumeWeight: number;
  totalPieces: number;
  originId: string;
  destinationId: string;
  sttPieces: STTPiecesDetail[];
  sttElexysNumber?: string;

  constructor(
    sttNumber: string,
    grossWeight: number,
    volumeWeight: number,
    totalPieces: number,
    originId: string,
    destinationId: string,
    sttPieces: STTPiecesDetail[],
    sttElexysNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.totalPieces = totalPieces;
    this.sttPieces = sttPieces;
    this.originId = originId;
    this.destinationId = destinationId;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class STIEntities {
  pagination: Pagination;
  data: STIData[];

  constructor(pagination: Pagination, data: STIData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class STIData {
  stiId: number;
  stiPartnerId: number;
  stiPartnerCode: string;
  stiPartnerName: string;
  stiTotalStt: number;
  stiTotalGrossWeight: number;
  stiTotalVolumeWeight: number;
  stiTotalPieces: number;
  stiCreatedAt: string;
  stiCreatedBy: number;
  stiCreatedName: string;
  stiUpdatedAt: string;
  stiTotalSTIDest: number;
  stiTotalStatusReturnPOD: number;
  stiTotalStatusReturnRTS: number;
  stiTotalStatusReturnHAL: number;
  hasSttNeedSti: boolean;

  constructor(
    stiId: number,
    stiPartnerId: number,
    stiPartnerCode: string,
    stiPartnerName: string,
    stiTotalStt: number,
    stiTotalGrossWeight: number,
    stiTotalVolumeWeight: number,
    stiTotalPieces: number,
    stiCreatedAt: string,
    stiCreatedBy: number,
    stiCreatedName: string,
    stiUpdatedAt: string,
    stiTotalSTIDest: number,
    stiTotalStatusReturnPOD: number,
    stiTotalStatusReturnRTS: number,
    stiTotalStatusReturnHAL: number,
    hasSttNeedSti: boolean

  ) {
    this.stiId = stiId;
    this.stiPartnerId = stiPartnerId;
    this.stiPartnerCode = stiPartnerCode;
    this.stiPartnerName = stiPartnerName;
    this.stiTotalStt = stiTotalStt;
    this.stiTotalGrossWeight = stiTotalGrossWeight;
    this.stiTotalVolumeWeight = stiTotalVolumeWeight;
    this.stiTotalPieces = stiTotalPieces;
    this.stiCreatedAt = stiCreatedAt;
    this.stiCreatedBy = stiCreatedBy;
    this.stiCreatedName = stiCreatedName;
    this.stiUpdatedAt = stiUpdatedAt;
    this.stiTotalSTIDest = stiTotalSTIDest;
    this.stiTotalStatusReturnPOD =  stiTotalStatusReturnPOD;
    this.stiTotalStatusReturnRTS = stiTotalStatusReturnRTS;
    this.stiTotalStatusReturnHAL = stiTotalStatusReturnHAL;
    this.hasSttNeedSti = hasSttNeedSti;
  }
}

export class DetailSttPiecesSTI {
  totalStt = 0;
  totalPieces = 0;
  totalSuccess = 0;
  totalFailed = 0;
  stiResult = "";
  stts = [] as DetailStt[];

  constructor(fields?: Partial<DetailSttPiecesSTI>) {
    Object.assign(this, fields);
  }
}

export class DetailStt {
  isAllowUpdateStatus = false;
  isPaid = true;
  errorMessage = "";
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttWoodPacking = false;
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttLastStatusId = "";
  sttPaymentStatus = "";
  isSti = false;
  isStiDest = false;
  piece = [] as DetailPieces[];
  sttDestinationDistrictUrsacode = "";
  packageNumber = null;
  sttDestinationRegionName = "";
  sttOriginRegionName = "";
  sttNoRef = "";

  constructor(fields?: Partial<DetailStt>) {
    Object.assign(this, fields);
  }
}
export class DetailPieces {
  sttPieceId = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceNo = 0;
  sttPieceLastStatusId = "";

  constructor(fields?: Partial<DetailPieces>) {
    Object.assign(this, fields);
  }
}

export class StiManifestData {
  stiId = 0;
  consolidatorName = "";
  originConsolidator = "";
  stiDate = "";
  totalStt = 0;
  totalPiece = 0;
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  createdName = "";
  totalSTIDest = 0;
  statusReturnPOD = 0;
  statusReturnRTS = 0;
  statusReturnHAL = 0;
  stt = [] as StiManifestStt[];
  needToStiCycleDate = "";
  needToStiStt: NeedToSTISTT[] = [];
  needToStiTotalGrossWeight = 0;
  needToStiTotalPiece = 0;
  needToStiTotalStt = 0;
  constructor(fields?: Partial<StiManifestData>) {
    Object.assign(this, fields);
  }
}

export class StiManifestStt {
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  piece = [] as StiManifestPiece[];
  stiOriginCity = "";
  stiDestCity = "";
  stiOriginRegion = "";
  stiDestRegion = "";
  stiGrossWeight = 0;
  stiVolumeWeight = 0;
  bagNo = "";
  statusReturn = "";
  isStiDest = 0;
  refNo = "";
  isDangerousGoods = false;
  constructor(fields?: Partial<StiManifestStt>) {
    Object.assign(this, fields);
  }
}

export class StiManifestPiece {
  sttPieceId = 0;
  sttPieceNo = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceLastStatusId = "";
  constructor(fields?: Partial<StiManifestPiece>) {
    Object.assign(this, fields);
  }
}

export class STIReverseScanTemporaryMeta {
  bagNo = "";
  isStiDest = 0;
  statusReturn = "";
  flag = "";
  pieces = 0;
  refNo = "";
  isDangerousGoods = false;
  isPaid = false;
  grossWeight = 0;
  constructor(fields?: Partial<STIReverseScanTemporaryMeta>) {
    Object.assign(this, fields);
  }
}
export class STIReverseScanTemporary {
  stId = 0;
  stAccountId = 0;
  stIsActive = 0;
  stSttNo = "";
  stProduct = "";
  stOrigin = "";
  stOriginName = "";
  stDestination = "";
  stDestinationCityName = "";
  stRegionId = "";
  stDeadlineReturn = "";
  stMeta: STIReverseScanTemporaryMeta = new STIReverseScanTemporaryMeta();
  stCreatedAt = "";
  stUpdatedAt = "";
  stDestinationName = "";
  stBookedType = "";
  stBookedId = 0;
  stBookedName = "";

  constructor(fields?: Partial<STIReverseScanTemporary>) {
    Object.assign(this, fields);
  }
}

export class STIReverseScanGenerate {
  success = false;
  message = "";
  stiId = 0;
  stt?: STIReverseScanGenerateData;
  bag: STIReverseScanGenerateData[] = []

  constructor(fields?: Partial<STIReverseScanGenerate>) {
    Object.assign(this, fields)
  }
}

export class STIReverseScanGenerateData {
  flag = "";
  statusReturn = "";
  deadlineReturn = "";
  bagNo = "";
  isStiDest = 0;
  refNo = "";
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttLastStatusId = "";
  sttPaymentStatus = "";
  bookingId = "";
  sttShipmentId = "";
  sttNoRefExternal = "";
  piece: StiManifestPiece[] = [];
  sttDestinationDistrictUrsacode = "";
  packageNumber = null;
  sttRegionId = "";
  sttRegionName = "";
  sttDestinationRegionName = "";
  sttOriginRegionName = "";
  stiDestOneBagScan = false;
  isDangerousGoods = false;
  bookedType = "";
  bookedId = 0;
  bookedName = "";
  isPaid = false;

  constructor(fields?: Partial<STIReverseScanGenerateData>) {
    Object.assign(this, fields)
  }
}

export class STIReverseScanDetail {
  stiId = 0;
  destinationCityId = "";
  destinationCityName = "";
  createdName = "";
  consolidatorName = "";
  originConsolidator = "";
  stiDate = "";
  totalStt = 0;
  totalPiece = 0;
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  totalStiDest = 0;
  totalStatusReturnPod = 0;
  totalStatusReturnRts = 0;
  totalStatusReturnHal = 0;
  stt: STIReverseScanGenerateData[] = [];
  needToStiTotalStt = 0;
  needToStiTotalPiece = 0;
  needToStiTotalGrossWeight = 0;
  needToStiCycleDate = "";
  needToStiStt: STIReverseScanGenerateData[] = [];

  constructor(fields?: Partial<STIReverseScanDetail>) {
    Object.assign(this, fields);
  }
}

export class STISTTDueSummary {
  id = 0;
  posName = "";
  total = 0;
  totalNow = 0;
  totalOverdue = 0;
  current = 0;
  type = "";

  constructor(fields?: Partial<STISTTDueSummary>) {
    Object.assign(this, fields);
  }
}

export class STIListBooked {
  bookedType = "";
  bookedId = 0;
  bookedName = "";

  constructor(fields?: Partial<STIListBooked>) {
    Object.assign(this, fields);
  }
}

export class STIListBookedEntities {
  pagination = new Pagination(1, 10, 0);
  data: STIListBooked[] = [];

  constructor(fields?: Partial<STIListBookedEntities>) {
    Object.assign(this, fields);
  }
}

export class STIScanFailed {
  sttOrBag = "";
  errorMessage = "";

  constructor(fields?: Partial<STIScanFailed>) {
    Object.assign(this, fields);
  }
}

export class NeedToSTISTT {
  sdId = 0;
  bagNo = "";
  bookedName = "";
  refNo = "";
  sttDestinationCityId = "";
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttLastStatusId = "";
  sttBookedAt = "";
  sttCreatedName = "";
  sttBookedName = "";
  deadline = "";
  sttGrossWeight = 0;

  constructor(fields?: Partial<NeedToSTISTT>) {
    Object.assign(this, fields);
  }
}

export class STISTTDueEntities {
  pagination = new Pagination(1, 20, 0);
  data: NeedToSTISTT[] = [];

  constructor(fields?: Partial<STISTTDueEntities>) {
    Object.assign(this, fields);
  }
}
